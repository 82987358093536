<template>
  <section class="tabla px-5 mb-2">
    <DataTable
      :value="_proveedoresStore._listaPrecioClienteData.rows"
      v-model:filters="filters"
      dataKey="Id"
      :row-class="rowClass"
      class="p-datatable-small"
      scrollable
      scrollHeight="600px"
      tableStyle="min-width: 50rem"
      :globalFilterFields="['ArticulcoId', 'ItemCode', 'ItemName', 'SWW', 'CodeBars', 'Id']"
    >
      <template #header>
        <div class="flex justify-between">
          <div class="flex">
            <strong class="mr-2 my-auto">
              Columnas
            </strong>
            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header"
                         @update:modelValue="onToggle"
                         placeholder="Seleccione las columnas que desea visualizar"
                         size="small"
                         class="p-inputtext-xs"
            />
          </div>
          <div class="flex justify-end my-auto">
            <strong class="mr-2 my-auto">
              Buscar
            </strong>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" width="350px" class="p-inputtext-lg"/>
            </span>
            <Button severity="info" label="Agrear" icon="pi pi-plus" class="mx-2" @click="onSaveProduct"/>
          </div>
        </div>
      </template>
      <Column v-for="(col, index) of selectedColumns" :header="col.header" :key="index" style="font-size: 0.75rem">
        <template #body="{data}">
          <div v-if="'N° Id' === col.header" class="text-start pt-3" >
            <p class="text-blue-600">{{ data.Id ?? index }}</p>
            <div class="flex gap-x-4">
              <button @click="onEditProduct(data)" class="underline italic idHijo text-white transition ease-in-out duration-500">Editar</button>
              <button class="underline italic idHijoEliminar text-white transition ease-in-out duration-500">Eliminar</button>
            </div>
          </div>
          <span class="text-xs" v-if="'Precio' === col.header">
            {{ $h.formatCurrency(data.VlrUnitario) }}
          </span>
          <div v-if="'Articulo' === col.header" class=" flex flex-col" >
            <div class="flex">
              <span class="text-xs mr-4">
                ID: {{ data.Id }}
              </span>
              <span class="text-xs">
                Cod: {{ data.ItemCode }}
            </span>
            </div>
            <span class="text-xs">
              Descripción: {{ data.ItemName }}
            </span>
            <div class="flex">
              <div class="text-xs flex mr-4">
                <span class="my-auto">Codigo Bars: {{ data.CodeBars }}</span>
              </div>
              <span class="text-xs my-auto">
                 CUM: {{ data.SWW }}
              </span>
            </div>
          </div>
          <img v-if="'Imagen' === col.header"
               :src="`https://int.pharmasan.net/api/config-ov/articulos/get-image/${data.PicturName}`" :alt="data.image"
               width="85" height="85" class="w-6rem shadow-2 border-round"/>
          <div class="flex flex-col" v-if="'Modificado' === col.header">
            <div class="grid grid-rows-2 grid-flow-col">
              <div class="row-span-2 my-auto" style="width: 90px">Creado</div>
              <div class="col-span-2">{{ data.CreadoPor }}</div>
              <div class="col-span-2">{{ $h.formatDate(data.CreatedAt, 'YYYY-MM-DD') }}</div>
            </div>
            <div class="grid grid-rows-2 grid-flow-col">
              <div class="row-span-2 my-auto" style="width: 90px">Actualizado</div>
              <div class="col-span-2">{{ data.ActualizadoPor }}</div>
              <div class="col-span-2">{{
                  data.ActualizadoPor ? $h.formatDate(data.UpdatedAt, 'YYYY-MM-DD') : null
                }}
              </div>
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
  </section>
  <section class="paginador px-5 pb-5">
    <Paginator v-model:first="_proveedoresStore._listaPrecioClienteData.offset"
               :rows="_proveedoresStore._listaPrecioClienteData.limit"
               :totalRecords="_proveedoresStore._listaPrecioClienteData.count"
               :rowsPerPageOptions="_proveedoresStore._listaPrecioClienteData.pageOptions"
               @page="onPage($event)"
    />
  </section>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { proveedoresStore } from '../../store/proveedores.store'
import { catalogoStore } from '../../catalogo/store/catalogo.store'
import { FilterMatchMode } from 'primevue/api'
const _proveedoresStore = proveedoresStore()
const _catalogoStore = catalogoStore()
const router = useRouter()
const route = useRoute()
const columns = ref([
  { header: 'N° Id' },
  { header: 'Precio' },
  { header: 'Articulo' },
  { header: 'Imagen' },
  { header: 'Modificado' }
])
const selectedColumns = ref(columns.value)
const onToggle = (val) => {
  selectedColumns.value = columns.value.filter(col => val.includes(col))
}
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})
onMounted(async () => {
  const id = useRoute().params.id
  _proveedoresStore.listaPrecioClienteData.proveedorId = id
  await _proveedoresStore.listaPrecioCliente()
})
const onPage = ({ first, page, pageCount, rows }) => {
  _proveedoresStore.listaPrecioClienteData.limit = rows
  _proveedoresStore.listaPrecioCliente()
}
const rowClass = () => {
  return 'p-selectable-row'
}
const onEditProduct = (data) => {
  _catalogoStore.setData(data)
  const _query = {
    Nombre: route.query.Nombre,
    Codigo: route.query.Codigo,
    ProveedorId: route.params.id
  }
  router.push({ name: 'pharmasan.compras.proveedores.catalogo.producto', params: { id: data.Id }, query: _query })
}
const onSaveProduct = async () => {
  _catalogoStore.resetData()
  const _query = {
    Nombre: route.query.Nombre,
    Codigo: route.query.Codigo,
    ProveedorId: route.params.id
  }
  router.push({ name: 'pharmasan.compras.proveedores.catalogo.producto', query: _query })
}
</script>
<style lang="scss" scoped>
.p-selectable-row:hover {
  .idHijo{
    color: blue
  }
  .idHijoEliminar{
    color: red
  }
}
@media (max-width: 800px) {
  .no-image {
    display: none;
  }
}
</style>
